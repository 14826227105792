// Import Bootstrap CSS
// @import "~bootstrap/scss/bootstrap";

// Import Bootstrap icons CSS
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Import Bootswatch Sandstone theme CSS
@import "./bootstrap.min.css";

// Import DNA loading spinner CSS
@import "./dnaSpinner.scss";


// CSS
.hidden {
    display: none;
}

#gene-selector {
    width: 210px;
}

.legend-text {
    display:inline-block;
    margin-bottom:1px;
    margin-right:5px;
    border-radius:50%;
    width:10px;
    height:10px;
}

.bg-primary {
    background-color: #00498f !important;
}

.modal-content {
    color: #00498f !important;
}